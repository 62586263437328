import React from 'react'

import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import { Utils } from '@azos/shared'
import { CoveragesCode } from '@azos/shared/src/lib/coverages'
import { WizardTutorialTag } from '@data/models'
import { TextPolicyStatus } from '@domain/models'
import { GuardiansModal } from '@presentation/components/molecules/Guardians'
import {
  OnboardingTopic,
  ProgressBanner,
} from '@presentation/components/molecules/Onboarding'
import { useMyPolicies } from '@presentation/providers/MyPoliciesProvider'
import { useOnboardingWizard } from '@presentation/providers/OnboardingWizardProvider'
import { useRouter } from '@presentation/providers/RouterProvider'
import { formatPresentationPolicy } from '@utils/policies'

import {
  PolicySelectViewType,
  PolicyViewManagement,
} from '../PolicyViewManagement'
import { Root } from './OnboardingManagement.styles'

const OnboardingCard: React.VFC = () => {
  const { policies } = useMyPolicies()
  const { wizard } = useOnboardingWizard()
  const { user } = useAuth()
  const Router = useRouter()

  const [isPolicyViewMangementOpen, setIsPolicyViewManagementOpen] =
    React.useState(false)
  const [isGuardianOpen, setIsGuardianOpen] = React.useState(false)

  const [viewPolicyType, setViewPolicyType] =
    React.useState<PolicySelectViewType>(PolicySelectViewType.details)
  const [actionType, setActionType] = React.useState<WizardTutorialTag | null>(
    null,
  )

  const total = React.useMemo(
    () => +Utils.sanitizer.onlyNumber(wizard?.header.total),
    [wizard?.header.total],
  )

  const hasPolicies = policies.length > 0

  const inactivePoliciesStatus = React.useMemo(
    () => [
      TextPolicyStatus.canceled,
      TextPolicyStatus.expired,
      TextPolicyStatus.pre_canceled,
    ],
    [],
  )

  const policiesFilteredByActive = React.useMemo(() => {
    return policies.filter(
      policy =>
        !inactivePoliciesStatus.includes(
          policy.status[0].label as TextPolicyStatus,
        ),
    )
  }, [inactivePoliciesStatus, policies])

  const policiesFilteredByActiveAndWithLifeInsurance = React.useMemo(() => {
    return policies.filter(policy => {
      const policyCoveragesCodes = policy.coverages.map(item => item.code)

      return (
        !inactivePoliciesStatus.includes(
          policy.status[0].label as TextPolicyStatus,
        ) &&
        policyCoveragesCodes.includes(CoveragesCode.COD_0001_SEGURO_DE_VIDA)
      )
    })
  }, [policies, inactivePoliciesStatus])

  const getPoliciesByActionType = React.useCallback(() => {
    if (actionType === WizardTutorialTag.beneficiaries) {
      return policiesFilteredByActiveAndWithLifeInsurance.map(policy =>
        formatPresentationPolicy(policy),
      )
    }

    return policiesFilteredByActive.map(policy =>
      formatPresentationPolicy(policy),
    )
  }, [
    actionType,
    policiesFilteredByActive,
    policiesFilteredByActiveAndWithLifeInsurance,
  ])

  const isB2B = React.useMemo(
    () => !!user?.isBroker || !!user?.isInsuredByBroker,
    [user],
  )

  React.useEffect(() => {
    if (!hasPolicies) Router.replace('/inicio')
  }, [hasPolicies, Router])

  const handleSecondaryAction = async (
    entity: WizardTutorialTag,
    action: string,
    executed: boolean,
  ) => {
    setActionType(entity)

    const slugCases: { [key: string]: () => void } = {
      [WizardTutorialTag.policy]: () => {
        if (action.endsWith('payment')) {
          setIsPolicyViewManagementOpen(true)
          return setViewPolicyType(PolicySelectViewType.payment)
        }

        setIsPolicyViewManagementOpen(true)
        setViewPolicyType(PolicySelectViewType.details)
      },
      [WizardTutorialTag.beneficiaries]: () => {
        setIsPolicyViewManagementOpen(true)
        setViewPolicyType(PolicySelectViewType.beneficiaries)
      },
      [WizardTutorialTag.guardians]: () => {
        setIsGuardianOpen(true)
      },
    }

    const handleSlug = slugCases[entity]

    if (handleSlug) {
      return handleSlug()
    }
  }

  const handleMainAction = (tag: WizardTutorialTag, title: string) => {
    setActionType(tag)

    const tagCases = {
      [WizardTutorialTag.policy]: () => {
        setIsPolicyViewManagementOpen(true)
        setViewPolicyType(PolicySelectViewType.details)
        analytics.dashboard.onboarding.viewOnboardingPolicyContent.execute({
          tela: title,
        })
      },
      [WizardTutorialTag.beneficiaries]: () => {
        setIsPolicyViewManagementOpen(true)
        setViewPolicyType(PolicySelectViewType.beneficiaries)
        analytics.dashboard.onboarding.viewOnboardingBeneficiaryContent.execute(
          {
            tela: title,
          },
        )
      },
      [WizardTutorialTag.guardians]: () => {
        setIsGuardianOpen(true)
        analytics.dashboard.onboarding.viewOnboardingGuardianContent.execute({
          tela: title,
        })
      },
    }

    const handleTag = tagCases[tag]

    if (handleTag) return handleTag()
  }

  return (
    <>
      <Root>
        <ProgressBanner
          percentage={total}
          title={wizard?.header.title ?? ''}
          description={wizard?.header.description ?? ''}
          color={wizard?.header.color ?? ''}
        />
        <div className="onboarding-card-container__actions">
          {wizard &&
            wizard.tutorial.map(action => (
              <OnboardingTopic
                key={action.tag}
                action={action}
                onSecondaryAction={handleSecondaryAction}
                onMainAction={handleMainAction}
              />
            ))}
        </div>
      </Root>

      <PolicyViewManagement
        open={isPolicyViewMangementOpen}
        onClose={() => setIsPolicyViewManagementOpen(false)}
        viewType={viewPolicyType}
        policiesList={getPoliciesByActionType()}
      />
      <GuardiansModal
        open={isGuardianOpen}
        onClose={() => setIsGuardianOpen(false)}
        hideShareLink={isB2B}
      />
    </>
  )
}

export default OnboardingCard
